<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="user-title">
          Notifications
        </div>
      </v-col>
    </v-row>
    <v-layout>
      <v-card v-for="notification in notifications" :key="notification._id">
        <v-row>
          <v-col>
            <v-card-text class="pt-3 pb-3 pl-5">{{
              notification.text
            }}</v-card-text>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              color="pink"
              class="mr-9"
              @click="onRemove(notification)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import io from "socket.io-client";
var socket = io.connect(process.env.VUE_APP_SOCKET_URL);

export default {
  data() {
    return {
      items: ["All"],
      headers: [
        { text: "", value: "text", sortable: false },
        { text: "Date", value: "date" },
      ],
      loading: false,
      userDialog: false,
      notifications: [
        { text: "Updated password", _id: 2 },
        { text: "Welcome!", _id: 1 },
      ],
      selectedItem: null,
      errorMessage: null,
      snackbar: false,
      filter: "",
      defaultItem: {
        _id: -1,
        first_name: "",
        last_name: "",
        address: null,
        address2: "",
        city: "",
        state: "",
        zip: "",
        email: "",
        phone: "",
        location: null,
      },
    };
  },
  methods: {
    ...mapActions("user", {
      fetchAllUsers: "fetchAllUsers",
      fetchStreets: "fetchStreets",
      addUser: "addUser",
      editUser: "editUser",
      deleteUser: "deleteUser",
    }),
    onEditUser(item) {
      this.selectedItem = item;
      this.userDialog = true;
    },
    onCloseUserDialog() {
      this.userDialog = false;
    },
    onAddUser() {
      this.selectedItem = null;
      this.userDialog = true;
    },
    onSaveUser(item, isAdd) {
      this.userDialog = false;
      this.loading = true;
      var params = {
        email: item.email,
        phone: item.phone,
        first_name: item.first_name,
        last_name: item.last_name,
        address: item.address,
        address2: item.address2,
        city: item.city,
        state: item.state,
        zip: item.zip,
      };
      if (item.location[0] && item.location[1])
        params.location = `${item.location[0]},${item.location[1]}`;
      if (!isAdd) {
        params["_id"] = item._id;
        this.editUser(params)
          .then(() => (this.loading = false))
          .catch((error) => {
            this.loading = false;
            if (this.response == undefined) {
              console.log(error);
            } else {
              this.showErrorMessage(error.response.data.message);
            }
          });
      } else {
        this.addUser(params)
          .then(() => (this.loading = false))
          .catch((error) => {
            this.loading = false;
            this.showErrorMessage(error.response.data.message);
          });
      }
    },
    onRemove(item) {
      //Show Dialog
      this.notifications = this.notifications.filter((n) => n._id != item._id);
    },
    deleteUserConfirm() {
      //Delete Action
      this.deleteDialog = false;
      this.loading = true;
      this.deleteUser({ _id: this.selectedItem._id })
        .then(() => (this.loading = false))
        .catch((error) => {
          this.loading = false;
          this.showErrorMessage(error.response.data.message);
        });
    },
    selectedStreet(item) {
      if (item == "All") {
        this.filter = "";
      } else {
        this.filter = item;
      }
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapGetters("user", {
      users: "getAllUsers",
      streets: "getStreets",
    }),
  },
  created() {
    this.loading = true;
    this.fetchAllUsers()
      .then(() => (this.loading = false))
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });

    this.fetchStreets();

    socket.on("AddedUser", (data) => {
      this.$store.commit("user/addUser", data);
      this.fetchAllUsers();
    });
    socket.on("UpdatedUser", (data) => {
      // console.log('updated user',data);
      this.$store.commit("user/editUser", data);
    });
  },
  watch: {
    streets() {
      this.items = ["All"];
      this.items = this.items.concat(this.streets);
    },
  },
};
</script>

<style scoped>
.user-title {
  font-size: 48px;
  font-family: "Poppins-Medium";
  color: #482684;
}
</style>
